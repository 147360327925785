import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import isLoggedIn, { getUserToken } from "../../../services/auth"
import axios from "axios";
import { API_BASE, STRIPE_KEY } from "../../../spectory-config";
import Layout from "./Layout"
import Button from "../../../components/elements/Button"
import TextInput from "../../../components/elements/Inputs/TextInput";
import Checkbox from "../../../components/elements/Inputs/Checkbox";
import LoadingSpinner from "../../../components/elements/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { changeCheckoutStep, setNotification, storeFormDataAction } from "../../../store/actions";
import PaymentTypes from "../paymentTypes";
import { CardNumberElement, CardExpiryElement, CardCvcElement, IbanElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';

const Payment = (props) => {

    const [loading, setLoading] = useState(false);
    const [loadingStart, setLoadingStart] = useState(false);

    const [acceptedGDPR, setAcceptedGDPR] = useState(false);
    const [gdprError, setGdprError] = useState(false);
    const [savePaymentInfo, setSavePaymentInfo] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);

    const { register: registerCouponCode, getValues: getCouponCodeValue, handleSubmit: handleCouponCodeSubmit } = useForm();

    const formData = useSelector(state => state?.checkout)
    const paymentType = useSelector(state => state?.checkout.payment?.paymentType)
    const paymentData = useSelector(state => state?.checkout.payment)
    const items = useSelector(state => state?.cart)
    const additionalData = useSelector(state => state?.checkout.additionalData)


    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch();
    dispatch(changeCheckoutStep({ currentStep: 'payment' }))


    const setCouponCode = (data) => {

        if (data.couponCode !== '') {

            let _additionalData = additionalData;

            _additionalData.couponCode = data.couponCode;
            dispatch(storeFormDataAction({ additionalData: _additionalData }));
        }

    }

    const proceedPayment = () => {

        setGdprError(false);

        if (!acceptedGDPR) {
            setGdprError(true);
            dispatch(setNotification({
                show: true,
                type: 'error',
                closable: true,
                title: 'Bitte akzeptieren Sie unsere Geschäftsbedingungen und Datenschutzbestimmungen.'
            }))
            return false;
        }

        processStripePayment();

        setLoading(true);
    }

    useEffect(() => {

        let neccessaryBilling = [
            'first_name',
            'last_name',
            'address_1',
            'postcode',
            'city',
            'email',
        ];

        let neccessaryShipping = [
            'first_name',
            'last_name',
            'address_1',
            'postcode',
            'city',
        ];

        let valid = true;

        Object.keys(formData.billing).forEach((key) => {
            if (neccessaryBilling.includes(key) && formData.billing[key] == '') valid = false;
        })

        if (!valid) {
            navigate('/kasse/billing');
            return false;
        }

        if (formData.shipping.shippingType === 'extraAddress') {
            Object.keys(formData.shipping).forEach((key) => {
                if (neccessaryShipping.includes(key) && formData.shipping[key] == '') valid = false;
            })

            if (!valid) {
                navigate('/kasse/shipping');
                return false;
            }
        }

        const token = getUserToken();

        if (token !== null) {

            setLoadingStart(true);

            // Get Preset Payment Methods
            var config = {
                method: 'POST',
                url: `${API_BASE}/customer/paymentmethods`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    token: token
                }
            };


            axios(config)
                .then(function (response) {

                    console.log("response", response.data)

                    if (response.data.c === 200) {
                        setPaymentMethods(response.data.data);
                    }

                    setLoadingStart(false);
                })
                .catch(function (error) {

                    console.log("response err", error)

                    setLoadingStart(false);
                });
        }

    }, [])

    const processStripePayment = () => {

        let _paymentType = paymentType;

        if ((!stripe || !elements) && _paymentType !== 'paypal') {
            return;
        }

        let cardNumber = null;
        let cardExpiry = null;
        let cardCVC = null;
        let ibanElement = null;

        let valid = true;

        switch (paymentType) {
            case 'card':

                if (!paymentData.creditCardNumber || !paymentData.creditCardExpiry || !paymentData.creditCardCVC) {
                    // valid = false;
                }

                cardNumber = elements.getElement(CardNumberElement);
                cardExpiry = elements.getElement(CardExpiryElement);
                cardCVC = elements.getElement(CardCvcElement);

                break;

            case 'sepa':

                if (!paymentData.sepaIban) {
                    valid = false;
                }

                ibanElement = elements.getElement(IbanElement);

                break;

            default:
                break;
        }


        if (!valid) {
            setLoading(false)
            // setHasError(true)
            return false;
        }

        let data = {
            billing: formData.billing,
            shipping: formData.shipping,
        }

        // Add Items
        let dataItems = [];

        items.forEach(item => {
            let newDataItem = {
                product_id: item.id,
                quantity: item.quantity,
            }

            if (item.variationId !== undefined) {
                newDataItem.product_variation_id = item.id;
            }

            if(item.additionalData !== undefined){
                newDataItem.additional_data = item.additionalData;
            }

            if (item.isCoupon !== undefined && item.isCoupon === true && item.price !== undefined && item.price > 0) {
                newDataItem.price = item.price;
            }
            
            dataItems.push(newDataItem)
        })

        data.items = dataItems;

        // Add Token & Check if is logged in
        if (getUserToken() !== null) {
            data.token = getUserToken();
        }

        if (additionalData.couponCode !== undefined && additionalData.couponCode !== '') {
            data.coupon_code = additionalData.couponCode.replace('-', '');
        }


        if (formData.register !== undefined && formData.register.wantRegister) {
            data.register = {
                email: formData.register.email,
                password: formData.register.password,
            }
        }

        let selectedPaymentMethod = null;

        if (_paymentType.includes('__')) {

            let splitted = _paymentType.split('__');

            _paymentType = splitted[0];
            selectedPaymentMethod = splitted[1];

        }

        data.use_discount = additionalData.useCustomerCardDiscount;

        data.payment_method = _paymentType;

        axios.post(`${API_BASE}/shop/order/create`, data)
            .then(function (response) {

                dispatch(changeCheckoutStep({ currentStep: 'done' }))

                const successUrl = `/kasse/success/${response.data.order_id}/${encodeURIComponent(formData.billing.email)}`;
                const waitingUrl = `/kasse/waiting/${response.data.order_id}/${encodeURIComponent(formData.billing.email)}`;

                if (response.data.is_free) {
                    navigate(successUrl);
                    return;
                }

                let stripeData = {};

                switch (_paymentType) {
                    case 'card':
                        stripeData = {
                            payment_method: { card: cardNumber },
                        };

                        if (savePaymentInfo) {
                            stripeData.setup_future_usage = 'on_session'
                        }
                        if (selectedPaymentMethod !== null) {
                            stripeData.payment_method = selectedPaymentMethod;
                        }
                        stripe.confirmCardPayment(
                            response.data.client_secret,
                            stripeData
                        ).then(function (result) {
                            if (result.error) {
                                navigate('/kasse/error/');
                            } else {
                                navigate(successUrl);
                            }
                        })

                        break;

                    case 'sofort':
                        stripeData = {
                            payment_method: {
                                sofort: {
                                    country: 'AT',
                                },
                                billing_details: {
                                    name: formData.billing.first_name + ' ' + formData.billing.last_name,
                                    email: formData.billing.email
                                }
                            },
                            // Return URL where the customer should be redirected after the authorization.
                            return_url: `http://localhost:8000${waitingUrl}`,
                        };

                        if (savePaymentInfo) {
                            stripeData.setup_future_usage = 'off_session'
                        }

                        if (selectedPaymentMethod !== null) {
                            stripeData.payment_method = selectedPaymentMethod;
                        }

                        stripe
                            .confirmSofortPayment(response.data.client_secret,
                                stripeData
                            )
                            .then(function (result) {
                                if (result.error) {
                                    console.log(result.error)
                                } else {
                                    console.log(result)
                                }
                            });
                        break;

                    case 'sepa':

                        stripeData = {
                            payment_method: {
                                sepa_debit: ibanElement,
                                billing_details: {
                                    name: `${formData.firstName} ${formData.lastName}`,
                                    email: formData.email,
                                },

                            },
                        };

                        if (savePaymentInfo) {
                            stripeData.setup_future_usage = 'off_session'
                        }

                        if (selectedPaymentMethod !== null) {
                            stripeData.payment_method = selectedPaymentMethod;
                        }

                        stripe
                            .confirmSepaDebitPayment(response.data.client_secret,
                                stripeData
                            )
                            .then(function (result) {
                                if (result.error) {
                                    navigate('/kasse/error/')
                                } else {
                                    navigate(successUrl)
                                }
                            });


                        break;

                    case 'paypal':

                        const url = response.data.url;
                        window.location.href = url;
                        break;
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }


    return (
        <Layout path={props.path}>

            <h4>Zahlung</h4>

            <h5>Gutschein</h5>
            <form onSubmit={handleCouponCodeSubmit(setCouponCode)}>
                <TextInput
                    type="coupon"
                    required
                    {...registerCouponCode('couponCode')}
                    label="Gutscheincode"
                    initialValue={additionalData?.couponCode}
                />
                <input type="submit" className={"simpleButton"} value="Aktualiseren" />
            </form>


            <PaymentTypes
                parentSetSavePaymentInfo={setSavePaymentInfo}
                storedPaymentMethods={paymentMethods}
            />

            <Checkbox
                onChange={(e) => {
                    setAcceptedGDPR(prev => (!prev));
                }}
                checked={acceptedGDPR}
                label={<span>Ich akzeptiere die <Link to="/agb">AGB</Link> und <Link to="/gdpr">Datenschutzbestimmungen</Link></span>}
                style={{ marginTop: 20, marginBottom: 20 }}
                required
                error={gdprError}
            />

            <div className="container" style={{ marginTop: 50 }}>
                <div className="col2"></div>
                <div className="col5">
                    <div>
                        <Button
                            title="Schritt zurück"
                            type="secondary"
                            destination="/kasse/shipping"
                            additionalStyle={{ width: '100%' }}
                        />
                    </div>
                </div>
                <div className="col5">
                    <div>
                        <Button
                            title="Kostenpflichtig bestellen"
                            type="primary"
                            action={proceedPayment}
                            additionalStyle={{ width: '100%' }}
                        />
                    </div>
                </div>
            </div>
            <LoadingSpinner active={loading} type="wholeContainerTransparent" label="Zahlung wird bearbeitet" />
            <LoadingSpinner active={loadingStart} type="page" />

        </Layout>
    )
}

export default Payment;
